<script>
import DefaultMixin from "@/components/leiloes/mixin"
import {ErpInput} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"

export default {
  name: 'Live',
  inject: ['page', 'erplayout'],
  mixins: [DefaultMixin],
  components: {
    ERow,
    ECol,
    ErpInput
  },
  data() {
    return {
      leilao: this.page.leilao,
      live: null
    }
  },
  computed: {},
  mounted() {
    this.page.setActive('live')
    // document.querySelector('.u-erp-layout-container').classList.add('leilao-telao-seletor')
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('leilao-telao-seletor')
  },
  methods: {
    testUrl() {
      let live = this.live
      if (/youtu/.test(live)) {
        live = live.replace('https://www.youtube.com/watch?v=', '')
        live = live.replace('https://youtube.com/watch?v=', '')
        live = live.replace('https://www.youtube.com/', '')
        live = live.replace('https://youtube.com/', '')
        live = live.replace('https://www.youtube.com/embed/', '')
        live = live.replace('https://www.youtube.com/embed', '')
        live = live.replace('https://youtu.be/', '')
        live = `https://www.youtube.com/embed/${live}?controls=0&autoplay=1&loop=1&rel=0&showinfo=0`
      }
      this.leilao.video = live
      return live
    },
    save() {
      this.comunicatorClass.defineUrlLive(this.leilao.id, this.testUrl()) // @TODO: Atualmente estamos somente preparados para Youtube.
        .then((response) => {
          console.log(response.data)
        })
        .catch(({response}) => {
          this.alertApiError(response)
        })
    },
    ativarGlobal () {
      this.dg('Transmissão ativada com sucesso!')
    },
    desativarGlobal () {
      this.dg('Transmissão desativada com sucesso!')
    }
  },
  meta: {
    title: 'Transmissão do Leilão',
    name: 'Transmissão do Leilão'
  }
}
</script>

<template>
  <div class="wrapper">
    <div class="text-center">
      <div class="font-bold">Vídeo atual:</div>
      <div v-if="leilao.video">
        <iframe width="70%" height="300" :src="leilao.video"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; loop"
                allowfullscreen></iframe>
      </div>
      <div class="m-t" v-else>
        Nenhuma transmissão informada.
      </div>

      <div style="display: block; max-width: 800px; margin: 40px auto" class="m-t">
        <e-row>
          <e-col class="flex items-center" style="max-width: 200px">Atualizar transmissão:</e-col>
          <e-col>
            <erp-input size="2" v-model="live"/>
          </e-col>
          <e-col>
            <e-btn @click="testUrl" label="Testar link"/>
            <e-btn @click="save" class="m-l" label="Atualizar transmissão"/>
          </e-col>
        </e-row>
        <e-row class="m-t-xxl">
          <e-col>Utilize os botões abaixo caso tenha adaptado no site a transmissão global</e-col>
        </e-row>
        <e-row>
          <e-col>
            <u-btn @click="ativarGlobal" no-caps label="Ativar Transmissão Global" color="positive" />
            <u-btn @click="desativarGlobal" no-caps label="Desativar Transmissão Global" color="negative" class="m-l" />
          </e-col>
        </e-row>

      </div>
    </div>
  </div>
</template>

<style src="../transmissao/assets/global.styl" lang="stylus"/>
